<script lang="ts">
	let headlineClass = "";
	export { headlineClass as class };
</script>

<h2
	class="text-primary m-0 text-center text-[1.375rem] font-bold uppercase tracking-[0.3em] md:text-[2rem] {headlineClass}"
>
	<slot />
</h2>
